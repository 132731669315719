.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.draggableElem {
  height: 60px;
  line-height: 20px;
  font-size: 20px;
  background: #FFFFFF;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-align: left;
  padding: 0 30px;
  color: #2A3948;
  border-radius: 1100px;
  -webkit-border-radius: 1100px;
  cursor: pointer;
  width: 320px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  font-size: 18px;
  margin: 16px 0;
}

@media only screen and (min-width: 800px) {
  .container {
    padding: 0 50px;
  }
  
  .draggableElem {
    height: 60px;
    line-height: 60px;
    font-size: 22px;
    width: 380px;
  }
}