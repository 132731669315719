.container {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.sliderBox {
  width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.titleSlider {
  background: #F19E86;
  display: inline-block;
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #ffffff;
  font-family: 'Avenir';
  font-size: 24px;
}
.sliderDescription {
  padding: 10px 20px;
}
.descriptionTitle {
  font-family: 'Avenir';
  font-size: 12px;
  line-height: 14px;
  color: #2A3948;
}
.descriptionText {
  font-family: 'Avenir';
  font-size: 16px;
  line-height: 22px;
  color: #2A3948;
  margin: 10px 0;
}
.sliderContainer {
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.sliderLabelContainer {
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sliderLabel {
  font-family: 'Avenir';
  font-size: 12px;
  line-height: 14px;
  color: #2A3948;
  font-weight: bold;
}
.arrowNext {
  margin-top: 40px;
}

.nextButton {
  width: 100%;
  background: #FFFFFF;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  height: 50px;
  font-size: 22px;
  line-height: 30px;
  color: #32668F;
}
.errorMessage {
  width: 100%;
  text-align: center;
  display: inline-block;
  color: #FF0000;
}

@media only screen and (min-width: 800px) {
  .container {
    padding: 30px 35px;
  }
  .titleSlider p {
    margin: 15px 0;
  }
  .sliderBox { 
    width: 400px;
  }
  .nextButton {
    width: 210px;
  }
}