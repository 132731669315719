@import-normalize; /* bring in normalize.css styles */
/* font family for Tiempos: */
@font-face {
  font-family: 'Tiempos';
  font-display: swap;
  src: local('Tiempos'), url('assets/fonts/Tiempos/TiemposText-Regular-optimized.ttf') format('truetype');
}

/* font family for Avenir: */
@font-face {
  font-family: 'Avenir';
  font-display: swap;
  src: local('Avenir'), url('assets/fonts/Avenir/Avenir-Book-01-optimized.ttf');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Avenir', 'Tiempos', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
