.modal {
  background: #F19E86;
  color: #FFFFFF;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-align: center;
  overflow-y: auto;
  z-index: 20;
 }

 .modalHeader {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.modalContent {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: calc(100% - 60px);  /* 60px is the height of the header */
}
  
.close {
  height: 18px;
  width: 18px;
  margin: 10px;
  color: #FFFFFF;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.menuOption {
  font-size: 2.6em;
  line-height: 60px;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration-line: none;
  -webkit-text-decoration-line: none;
}

.menuModalContent {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 50%;
}

/* ------------- LinkAndModal classes -------------- */

.linkModal {
  text-decoration-line: underline;
  -webkit-text-decoration-line: underline;
  cursor: pointer;
  font-size: 18px;
  line-height: 34px;
  color: #2A3948;
  margin: 5px;
}
.customContent {
  width: 100%
}

.customModalContent {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.modalTitle {
  font-size: 38px;
  font-weight: 100;
  line-height: 52px;
  text-align: center;
  color: #FFFFFF;
  margin: 0 0 0.67em;
}

.customClose {
  text-decoration-line: underline;
  -webkit-text-decoration-line: underline;
  cursor: pointer;
  font-size: 22px;
  line-height: 30px;
}
.customFooter {
  margin: 20px 0;
}

.columnContent {
  margin: 0 auto;
  column-width: 16em;
  column-count: 1;
  text-align: left;
  column-gap: 4em;
}

.paragraph {
  line-height: 25px;
  font-size: 18px;
}
.firstParagraph {
  margin-top: 0;
}


@media only screen and (min-width: 700px) {
  .customContent {
    width: 80%;
  }

  .menuModalContent {
    padding: 0 60px;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }

  .menuOption {
    font-size: 4.8em;
    line-height: 109px;
    margin: 0.2em 0;
  }
}